import {createTheme, Shadows} from "@mui/material";
import {CustomMuiTheme} from "@plumeuk/shapeshift-common/theming";
import {plumePalette, transitionTime, toolBarHeight} from "../constants";

declare module "@mui/material/styles" {
	interface Theme extends CustomMuiTheme {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends CustomMuiTheme{}
}

export const defaultTheme = createTheme({
	toolbarHeight: toolBarHeight,
	breakpoints: {
		//Mobile  |		 x < sm
		//Tablet  |	sm < x < md
		//Desktop |		 x > md
		values: {
			// extra-small
			xs: 0,
			// small
			sm: 700,
			// medium
			md: 1000,
			// large
			lg: 1200,
			// extra-large
			xl: 1536
		}
	},
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		background: {
			default: plumePalette.background
		},
		primary: {
			main: plumePalette.Grey
		},
		secondary: {
			main: plumePalette.Blue
		},
		success: {
			main: plumePalette.success
		},
		error: {
			main: plumePalette.error
		}
	},
	typography: {
		fontFamily: "HelveticaNeueCyr",
		body1: {
			fontSize: "18px"
		},
		h1: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "50px",
			lineHeight: "120%"
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "46px",
			lineHeight: "120%"
		},
		h3: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "22px",
			lineHeight: "1"
		},
		h4: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "1"
		},
		h5: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "22px",
			lineHeight: "28px"
		},
		body2: {
			fontSize: "15px"
		},
		h6: {
			fontSize: "20px"
		},
		button: {
			fontSize: "15px",
			textTransform: "none"
		}
	},
	navBar: {
		statusprofile: {
			backgroundColor: plumePalette.Blue
		}
	},
	components: {
		MuiToolbar: {
			styleOverrides: {
				root: {
					color: plumePalette.Grey,
					height: toolBarHeight,
					backgroundColor: plumePalette.light,
					borderBottom: "1px solid" + plumePalette.Grey,
					"& [class*='MuiTabs']": {
						display: "none"
					},
					"& [class*='navBarLogo']": {
						"& img": {
							height: "calc(100% - 20px)",
							margin: "10px 0px 8px"
						}
					},
					"& [class*='bellIconContainer']": {
						width: "60px",
						height: "60px",
						display: "none"
					},
					"& [class*='iconContainer']": {
						width: "57px",
						height: "57px",
						fontSize: "17px",
						fontWeight: "600",
						letterSpacing: "2px",
						margin: "16px 20px",
						padding: "3px 0px 0px 2px",
						lineHeight: "1",
						transition: transitionTime,

						"&:hover": {

							border: 0,
							outline: 0,
							opacity: "0.65",
							transition: transitionTime

						}

					},
					"& [class*='pageBaseProfile']": {

						marginRight: "12px"

					}
				}
			}
		},
		MuiModal: {
			styleOverrides: {
				root: {
					"& [class*='-form']": {
						overflowY: "unset"
					},
					"& [class='closeIcon']": {
						right: "45px"
					},
					"& [class*='errorMessage'] p": {
						paddingTop: "0px",
						fontSize: "12px",
						textTransform: "uppercase"
					},
					"& [class*='-label']": {
						fontSize: "15px",
						opacity: "0.4"
					},
					"& [class*='contentContainer']": {
						padding: "40px 40px"
					},
					"& [class*='Mui-selected']": {
						color: plumePalette.darkGrey
					},
					"& [class*='inputText']": {
						marginTop: "6px"
					},
					"& [class*='MuiSvgIcon'] [data-testid='VisibilityOffIcon']": {
						position: "absolute",
						marginTop: "1px"
					},
					"& [class*='root-title']": {
						marginBottom: "12px"
					},
					"& [class*='MuiTextField'] input": {
						border: "1px solid " + plumePalette.lightGrey,
						padding: "12px 25px 8px 14px!important",
						fontSize: "14px",
						width: "calc(100% - 40px)",
						borderRadius: "8px"
					}
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					"& [class*='paper']": {
						backgroundColor: plumePalette.background + " !important",
						paddingTop: toolBarHeight + " !important",
						"& p": {
							color: plumePalette.Grey
						}
					},
					"& [class*='outlineButton']": {
						borderColor: "#fff",
						border: "2px solid" + plumePalette.Blue,
						padding: "18px 24px 16px 28px",
						borderRadius: "13px",
						transition: transitionTime,
						"& svg": {
							marginTop: "-4px",
							transition: transitionTime
						},
						"& p": {
							fontWeight: "500",
							transition: transitionTime
						},
						"&:hover": {
							background: plumePalette.Blue,
							transition: transitionTime,
							"& p": {
								color: plumePalette.background,
								transition: transitionTime
							},
							"& svg": {
								fill: plumePalette.background,
								transition: transitionTime
							}
						}
					},
					"& [class*='sectionContainer']": {

						borderBottom: `1px solid ${plumePalette.Grey}`

					},
					"& [class*='coverContainer']": {

						display: "none"

					},
					"& [class*='avatarContainer']": {

						marginTop: "40px",
						borderBottom: "1px solid" + plumePalette.Grey,
						paddingBottom: "18px"

					},
					"& [class*='progressBar']": {

						background: plumePalette.transparentGrey

					},
					"& [class*='-name']": {

						color: plumePalette.Grey,
						fontSize: "24px",
						marginTop: "9px",
						marginBottom: "10px"

					},
					"& [class*='MuiAvatar']": {

						marginTop: "0px"

					}

				}
			}
		},
		MuiList: {
			styleOverrides: {
				root: {
					"& [class*='listItemContainer']": {

						borderBottom: "1px solid " + plumePalette.lightGrey

					}
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: "5px",
					height: "8px",
					backgroundColor: plumePalette.Blue + "20"
				},
				bar: {
					backgroundColor: plumePalette.Blue,
					borderRadius: "10px"
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: "16px",
					padding: "24px 40px 16px",
					transition: transitionTime,
					"&.Mui-selected": {

					},

					"&:hover": {
						opacity: "0.7",
						transition: transitionTime
					},
					"& [class*='MuiTouchRipple']": {
						visibility: "hidden !important"
					}
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					borderBottom: 0,
					lineHeight: 0,
					position: "relative",
					overflow: "visible"
				},
				indicator: {
					height: "4px",
					backgroundColor: plumePalette.Blue
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: "15px",
					backgroundColor: plumePalette.Blue,
					color: "white",
					padding: "16px 30px 14px",
					borderRadius: "13px",
					transition: "opacity .3s ease-in-out",
					"&:hover": {
						backgroundColor: plumePalette.Blue,
						opacity: 0.8
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					},
					"&[class*='prevBtn']": {
						padding: "16px 32px 14px 32px",
						"& svg": {
							width: "0.85em",
							height: "0.85em",
							position: "relative",
							top: "50%",
							marginLeft: "-10px",
							marginTop: "-28px"
						}
					},
					"&[class*='nextBtn']": {
						padding: "16px 32px 14px 32px",
						"& svg": {
							width: "0.85em",
							height: "0.85em",
							position: "relative",
							top: "50%",
							marginRight: "-10px",
							marginTop: "-29px"
						}
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				standard: {
					"&:focus": {
						backgroundColor: "transparent"
					}
				},
				outlined: {
					padding: "12px 16px 10px"
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: "#fff",
					border: "none !important",
					padding: "0 0 0 0 !important",
					borderRadius: "10px",
					"& textarea": {

						padding: "12px 50px 9px 16px",
						borderRadius: "10px",
						border: "1px solid" + plumePalette.lightGrey,
						transition: "border " + transitionTime,

						"&:focus": {
							border: "1px solid" + plumePalette.Blue,
							transition: "border " + transitionTime
						}

					},
					"& [class*='positionEnd']": {

						marginLeft: "-32px"

					}
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				root: {
					paddingBottom: "0px",
					padding: "0 0 0 0 !important",
					fontSize: "16px"
				}
			}
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					"&[data-testid='CheckCircleIcon']": {
						fontSize: "32px !important",
						fill: plumePalette.success,
						marginTop: "-1px"
					},
					"&[data-testid='CancelIcon']": {
						fontSize: "32px !important",
						fill: plumePalette.error,
						marginTop: "-1px"
					}
				}
			}
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					fontSize: "18px",
					fontWeight: "500",
					letterSpacing: "2px",
					padding: "2px 0 0 2px"
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: plumePalette.Blue,
					color: "#fff"
				}
			}
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					borderRadius: "8px",
					fontSize: "14px !important",
					marginTop: "-20px",
					minWidth: "160px",
					border: "1px solid" + plumePalette.lightGrey,
					"& li": {

						fontSize: "15px !important",
						padding: "12px 15px 10px",
						backgroundColor: plumePalette.background + "!important",
						transition: transitionTime,

						"&:hover": {

							backgroundColor: plumePalette.light + "!important",
							transition: transitionTime

						}

					}
				}
			}
		},
		MuiMenu: {
			styleOverrides: {
				list: {

					paddingTop: 0,
					paddingBottom: 0

				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					fontSize: "16px",
					paddingLeft: "3px",
					"& [class*='notchedOutline']": {
						border: "1px solid" + plumePalette.lightGrey + "!important",
						borderWidth: "1px !important",
						"&:focus": {
							border: "1px solid" + plumePalette.Blue
						}
					}
				}
			}
		},
		MuiInputAdornment: {
			styleOverrides: {
				root: {

					marginTop: "-1px",

					"& svg": {

						width: "0.8em",
						height: "0.8em"

					}

				}
			}
		}
	}
});
