import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {makeStyles} from "tss-react/mui";
import {NotesSection} from "@plumeuk/shapeshift-common/notes";
import {Files} from "@plumeuk/shapeshift-common/files";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {ModuleCompleteButton, ModuleUnCompleteButton, ModuleTabs, ModuleTab} from "@plumeuk/shapeshift-common/module";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {MiniQuizzer} from "./MiniQuizzer";
import {ICourseCurriculumModule, ILesson} from "@plumeuk/shapeshift-types";
import {VideoPlayer} from "../../../components/VideoPlayer/VideoPlayer";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {plumePalette, transitionTime} from "../../../constants";
import {useSetModuleCompletion} from "@plumeuk/shapeshift-common/hooks";
import {getNextLinearModule} from "@plumeuk/shapeshift-common/common";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {Links} from "@plumeuk/shapeshift-common/links";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {MiniSurvey} from "./MiniSurvey";

const useStyles = makeStyles()((theme) => ({
	title: {
		marginBottom: "36px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "24px"
		}
	},
	centeredContainer: {
		paddingBottom: "100px",
		maxWidth: "900px",
		"& [class*='sendButtonInputAdornment']": {
			marginRight: "10px",
			position: "relative",
			top: "10px"
		}
	},
	links: {
		marginBottom: "40px"
	},
	lessonCompleteBtn: {
		float: "right",
		marginLeft: "20px",
		padding: "15px 32px 13px",
		border: "1px solid " + plumePalette.Blue,
		borderRadius: "13px",
		transition: transitionTime,
		"&:hover": {
			backgroundColor: plumePalette.success,
			borderColor: plumePalette.success,
			opacity: "1",
			transition: transitionTime
		},
		"&[class*='moduleUnCompleteButton']": {
			"&:hover": {
				backgroundColor: plumePalette.Blue,
				color: plumePalette.background,
				borderColor: plumePalette.Blue,
				opacity: "1",
				transition: transitionTime
			}
		}
	},
	content: {
		marginBottom: "64px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "18px",
			marginBlockEnd: "1.15em",
			color: plumePalette.darkGrey
		}
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px"
	},
	miniQuizzer: {
		marginBottom: "80px",
		minHeight: "550px"
	},
	nextBtn: {
		position: "absolute",
		[theme.breakpoints.down("sm")]: {
			margin: 0,
			borderRadius: "0",
			left: 0,
			top: "250px",
			padding: "14px 0",
			right: "0px",
			"& svg": {
				fontSize: "20px",
				[theme.breakpoints.down("sm")]: {
					marginTop: "-1px !important"
				}
			}
		},
		[theme.breakpoints.up("sm")]: {
			zIndex: 10,
			background: "#fff",
			color: theme.palette.secondary.main,
			right: "-160px",
			fontSize: "22px",
			opacity: 0.7,
			top: "200px",
			borderRadius: 100,
			padding: "22px 150px 22px 22px",
			transition: "all 0.2s ease-in-out",
			"&:hover": {
				opacity: 1,
				right: "-140px",
				color: theme.palette.secondary.main,
				background: "#fff"
			},
			"& span": {
				display: "none"
			},
			"& svg": {
				fontSize: "48px"
			}
		}
	},
	disabledBtn: {
		cursor: "not-allowed",
		opacity: .5
	},
	tabs: {
		marginBottom: "65px",
		position: "relative",
		overflow: "visible",

		"&:after": {
			width: "300vw",
			height: "1px",
			content: "''",
			display: "block",
			borderBottom: "1px solid " + plumePalette.Grey,
			position: "absolute",
			left: "-400px",
			bottom: "0px",
			zIndex: "0"
		}
	},
	tab: {
		color: plumePalette.Grey,
		fontSize: "18px",
		padding: "32px"
	},
	activeTab: {
		color: `${plumePalette.darkGrey} !important`
	}
}));


interface IProps {
	lesson?: ILesson,
	lessonSlug?: string,
	courseSlug?: string
}

export const LessonPageContent: FC<IProps> = ({lesson}) => {
	const {classes, cx} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {notify} = useContext(NotificationContext);
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const [passedQuiz, setPassedQuiz] = useState<boolean>(false);
	const videoRef = useRef<VideoPlayerRef>(null);
	const {setComplete, apiResult: setCompleteApiResult} = useSetModuleCompletion();
	const [nextModule, setNextModule] = useState<ICourseCurriculumModule>();
	const [surveyComplete, setSurveyComplete] = useState(false);

	const LESSON_TABS: { label: string; value: ModuleTab }[] = [
		{
			label: "Lesson",
			value: "overview"
		},
		{
			label: "Comments",
			value: "comments"
		},
		{
			label: "Notes",
			value: "notes"
		}
	];

	//set module to complete if viewed and progression tracking off.
	//this means the default nav for get next available incomplete will not continue to navigate to module
	useEffect(() => {
		if (lesson?.progression === false && courseSlug && lessonSlug)
			setComplete(courseSlug, lessonSlug, "lesson", true)
	}, [lesson])

	useEffect(() => {
		if (setCompleteApiResult.data) {
			const status = !!setCompleteApiResult?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status
			})
		}

	}, [setCompleteApiResult])

	useEffect(() => {
		if (courseSlug && lessonSlug && curriculum)
			setNextModule(getNextLinearModule(curriculum, {slug: lessonSlug, type: "lesson"}))
	}, [courseSlug, lessonSlug, curriculum])

	useEffect(() => {
		if (lesson && (!(lesson as any).quiz || (lesson as any).quiz.scoreRequired === 0)) {
			setPassedQuiz(true);
		}
	}, [lesson])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if (e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if (!e.isLoading && !e.isError && e.statusCode === 200) {
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status
			})

			if (e?.data?.complete) {
				notify(null, "Lesson completed", INotificationSeverity.success, 4000);
				navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 4000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	const handleNextLesson = (): void => {
		if (!curriculum || !lessonSlug)
			return;

		navigate(`/course/${courseSlug}`)
	}


	useEffect(() => {
		if(!(lesson as any)?.survey){
			setSurveyComplete(true)
		}
	}, [(lesson as any)?.survey]);

	return (
		<Box>
			{lesson?.videoUrl && <>
				<VideoPlayer ref={videoRef} videoUrl={lesson.videoUrl} />
			</>}
			<CenteredContainer className={classes.centeredContainer}>
				<ModuleTabs tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} moduleTabs={LESSON_TABS}/>
				{tabSelected === "overview" && <>
					<PageTitle className={classes.title} title={lesson?.title} subtitle={lesson?.subtitle} />
					<WYSIWYG className={classes.content}>
						{lesson?.content ?? ""}
					</WYSIWYG>
					{(lesson as any)?.quiz &&
						<MiniQuizzer
							autoStart
							className={classes.miniQuizzer}
							onComplete={result => setPassedQuiz(result.pass)}
							quiz={(lesson as any).quiz}
						/>
					}

					{(lesson as any)?.survey &&
						<MiniSurvey
							autoStart
							className={classes.miniQuizzer}
							onComplete={() => setSurveyComplete(true)}
							survey={(lesson as any).survey}
						/>
					}

					{lesson?.files && <>
						<Typography className={classes.fileTitle} variant="h5">Files</Typography>
						<Files className={classes.files} files={lesson.files} />
					</>}

					{lesson?.links && lesson.links.length > 0 && <>
						<Typography className={classes.fileTitle} variant="h5">Links</Typography>
						<Links className={classes.links} links={lesson.links}/>
					</>}

					{surveyComplete && courseSlug && lesson?.progression !== false && lesson?.slug && !lesson?.complete &&
						<ModuleCompleteButton
							courseSlug={courseSlug}
							moduleSlug={lesson.slug}
							type="lesson"
							className={classes.lessonCompleteBtn}
							onApiUpdate={handleCompletion}
						/>
					}
					{surveyComplete && ((lesson?.progression === false || lesson?.complete)) &&
						<Button className={classes.lessonCompleteBtn} onClick={() => handleNextLesson()}>
							Next {nextModule?.type}<NavigateNextIcon style={{position: "relative", right: "-10px"}} />
						</Button>}


					{(surveyComplete === false) && <Button
						className={cx(classes.disabledBtn, classes.lessonCompleteBtn)}
						onClick={() => notify("Please complete the survey before moving on", "", INotificationSeverity.warning, 5000)}
					>
						Mark as completed
					</Button>}
					{/* {courseSlug && lesson?.progression !== false && lesson?.slug && lesson?.complete &&
						<ModuleUnCompleteButton
							courseSlug={courseSlug}
							moduleSlug={lesson.slug}
							type="lesson"
							className={classes.lessonCompleteBtn}
							onApiUpdate={handleCompletion}
						/>
					} */}
				</>}
				{(tabSelected === "comments" && lesson?.progression !== false) && <CommentsSection courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug}/>}
				{tabSelected === "notes" && <NotesSection courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} videoRef={videoRef} />}
			</CenteredContainer>

		</Box>
	);
}